<template>
  <div class="page-login">
    <div class="page-login-container">
      <div class="page-login-logo">
        <img src="../../assets/xinxiang.png"
             alt="logo">
      </div>
      <div class="page-login-title">
        本地优惠卡券
      </div>
      <el-form ref="form"
               :model="form">
        <el-form-item>
          <el-input prefix-icon="el-icon-user"
                    placeholder="请输入用户名"
                    size="large"
                    v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input show-password
                    size="large"
                    placeholder="请输入密码"
                    prefix-icon="el-icon-lock"
                    @keyup.enter.native="onSubmit('form')"
                    v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="loginStatus"
                     class="width100"
                     @click="onSubmit('form')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Config from '@/config'
export default {
  name: 'Login',
  data () {
    return {
      Config,
      loginStatus: false,
      redirect: '',
      form: {
        tel: '',
        password: ''
      },
      rules: {
        tel: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }
        ]
      }
    }
  },
  components: {
  },
  mounted () {
  },
  methods: {
    getOtherQuery (query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    onSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loginStatus = true
          this.$store.dispatch('user/login', this.form).then(res => {
            this.loginStatus = false
            if (res.role === 'ADMIN') {
              const redirect = this.$route.query.redirect || '/'
              const query = this.getOtherQuery(this.$route.query)
              this.$router.push({ path: redirect, query: query })
            } else {
              this.$router.push('/')
            }
          }).catch(err => {
            this.loginStatus = false
            console.log(err)
            this.$message.error(err)
          })
        } else {
          this.$message.error('Fail!')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  height: 100%;
  background-image: url(../../assets/login-bg.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  .page-login-logo {
    text-align: center;
    img {
      width: 100px;
    }
  }
  .page-login-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, '\5FAE\8F6F\96C5\9ED1', Arial, sans-serif;
    line-height: 1.5;
    color: #515a6e;
  }
  .page-login-container {
    padding: 32px 0 24px 0;
    width: 384px;
    margin: 0 auto;
  }
}
</style>
